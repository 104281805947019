export const PROMPTS = [
    "I know the best spot in town for ",
    "A shower thought I recently had ",
    "A life goal of mine ",
    "A random fact I love is ",
    "A social cause I care about ",
    "All I ask is that you ",
    "An overshare  ",
    "Believe it or not, I  ",
    "Best travel story  ",
    "Biggest risk I’ve taken  ",
    "Change my mind about  ",
    "Dating me is like  ",
    "Do you agree or disagree that  ",
    "Don’t hate me if I  ",
    "Fact about me that surprises people  ",
    "First round is on me if  ",
    "Give me travel tips for  ",
    "I bet you can’t  ",
    "I geek out on  ",
    "I get along best with people who  ",
    "I go crazy for  ",
    "I recently discovered that  ",
    "I take pride in  ",
    "I want someone who  ",
    "I won’t shut up about  ",
    "I’ll brag about you to my friends if  ",
    "I’m convinced that  ",
    "I’m overly competitive about  ",
    "I’m weirdly attracted to  ",
    "If loving this is wrong, I don’t want to be right",
    "I’ll fall for you if  ",
    "I’ll introduce you to my family if  ",
    "I’ll know I’ve found the one when  ",
    "I’ll know it’s time to delete Hinge when",
    "I’ll pick the topic if you start the conversation",
    "I’m a regular at  ",
    "I’m looking for  ",
    "I’m the type of texter who  ",
    "Let’s debate this topic  ",
    "Let’s make sure we’re on the same page about",
    "Most spontaneous thing I’ve done  ",
    "My love language is  ",
    "My biggest date fail  ",
    "My greatest strength ",
    "My ideal date from home ",
    "My mantra is  ",
    "My most controversial opinion is  ",
    "My most irrational fear  ",
    "My simple pleasures  ",
    "Never have I ever  ",
    "One thing I’ll never do again  ",
    "Something that’s non-negotiable for me is  ",
    "Teach me something about  ",
    "The award I should be nominated for  ",
    "The best way to ask me out is by  ",
    "The dorkiest thing about me is  ",
    "The hallmark of a good relationship is  ",
    "The key to my heart is  ",
    "The one thing I’d love to know about you is  ",
    "The one thing you should know about me is  ",
    "The secret to getting to know me is  ",
    "The way to win me over is  ",
    "This year, I really want to  ",
    "Together, we could  ",
    "Try to guess this about me  ",
    "Two truths and a lie  ",
    "Typical Sunday  ",
    "Unusual skills  ",
    "We’ll get along if  ",
    "Weirdest gift I’ve given or received  ",
    "We’re the same type of weird if  ",
    "What I order for the table  ",
    "What if I told you that  ",
    "Which is more important to you  ",
    "Worst idea I’ve ever had  ",
    "You should *not* go out with me if  ",
    "You should leave a comment if  ",
    "You’ll know I like you if  ",
]
